<template>
	<div class="spread_use">
		<el-card>
			<el-row :gutter="20" style="text-align: left;">
				<el-col :span="24">
					<i class="el-icon-arrow-left" style="color: #30aaf1; cursor: pointer" @click="$router.go(-1)">
			            <span>返回优惠券列表</span>
			        </i>
			         - 
		            <span>优惠券卡密</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row :gutter="10"  style="text-align: left;margin-bottom: 15px;">
				<el-col :span="6">
					<el-form label-width="80px">
						<el-form-item label="输入查询">
							<el-input v-model="searchYHQ" placeholder="请输入优惠券卡密"></el-input>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="18">
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="default" icon="el-icon-refresh" @click="reset">重置</el-button>
				</el-col>
			</el-row>
			<el-table :data="use_data" :default-sort="{prop:'coupon_sort',order:'descending'}" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" style="width: 100%">
				<el-table-column prop="coupon_km" label="卡密"></el-table-column>
				<el-table-column prop="send_str" label="领取" align="center"></el-table-column>
				<el-table-column prop="use_str" label="使用" align="center"></el-table-column>
				<el-table-column prop="nickName" label="领取人" align="center"></el-table-column>
				<el-table-column prop="paifa_srt" label="获取方式" align="center"></el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	let that;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				coupon_id:'',
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				searchYHQ: '',
				use_data: []
			}
		},
		computed: {
			tableCount() {
				return this.user_data.length
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				if(this.$route.query.coupon_id){
					this.coupon_id = this.$route.query.coupon_id;
					this.getCouponUseList();
				}
			}
		},
		methods: {
			getCouponUseList() {
				var url = 'coupon/coupon_use_list';
				let params = {
					coupon_id: this.coupon_id,
					coupon_name: this.searchYHQ,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.use_data = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getCouponUseList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getCouponUseList();
			},
			// 搜索
			search() {
				this.getCouponUseList();
			},
			// 重置
			reset() {
				this.searchYHQ = '';
				this.getCouponUseList();
			},
		},
	}
</script>

<style lang="scss">
	.spread_coupon{
		.ml{
		  margin-left: 12px;
		}
		.btns{
		  .el-button{
		    margin: 5px;
		  }
		}
		.el-table .cell {
		    white-space: nowrap!important;
		}
	}
</style>